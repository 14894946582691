<template>
  <div
    class="w-11/12 px-6 py-6 md:px-10 md:py-10 mx-4 mt-1 bg-white rounded-2xl shadow-lg"
  >
    <div class="mb-3 mx-2 text-left">
      <h2 class="text-xl">
        <b>Datos de contacto</b>
      </h2>
      <h2>
        <a-tooltip
          v-if="!loading && noEdit"
          placement="right"
          title="Presione el botón para habilitar la edición"
        >
          <a-button
            id="edit"
            type="primary"
            ghost
            shape="circle"
            @click="startEdit"
            class="w-10 xl:w-10 text-white p-0"
          >
            <a-icon
              type="edit"
              :style="{ fontSize: '17px', color: 'blue' }"
              class="font-normal"
            />
          </a-button>
          <label
            for="edit"
            class="cursor-pointer text-sm font-thin mx-2 relative top-3"
            >Editar</label
          >
        </a-tooltip>
        <span v-else class="text-sm mt-3 text-black"> Edición habilitada</span>
      </h2>

      <form action="" ref="userForm">
        <div v-if="form.person.type_identification !== 3">
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              ref="names"
              type="text"
              label="Nombres"
              placeholder="Julio Roman"
              required="true"
              :maxlength="25"
              v-model="form.first_name"
              :disabled="noEdit"
            />
            <BaseInput
              ref="last_name"
              type="text"
              label="Apellidos"
              v-model="form.last_name"
              :disabled="noEdit"
              placeholder="Henriquez"
              required="true"
              :maxlength="24"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              type="text"
              :required="true"
              label="Tipo de identificación"
              v-model="form.person.type_identification_name"
              :disabled="true"
            />
            <BaseInput
              type="text"
              :required="true"
              label="Número identificación"
              v-model="form.person.identification"
              :disabled="true"
            />
          </div>

          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              type="text"
              :required="true"
              label="Fecha de nacimiento"
              v-model="form.person.birth_date"
              :disabled="noEdit"
              v-if="form.person.birth_date && noEdit"
            />
            <BaseDate
              v-if="!noEdit"
              ref="birthdate"
              id="birthdate"
              :required="true"
              label="Fecha de nacimiento"
              placeholder=""
              v-model="form.person.birth_date"
              :value="form.person.birth_date"
              :disabledDate="disabledDate"
              :defaultDate="form.person.birth_date"
              required="true"
            />
            <BaseInput
              ref="address"
              id="address"
              label="Dirección física"
              v-model="form.address"
              :maxlength="50"
              placeholder="Ejemplo: Cra. 1 ## 1A-11"
              :disabled="noEdit"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseSelect
              v-if="noEdit"
              ref="phone_country_abrev"
              id="phone_country_abrev"
              label="País del teléfono"
              required="true"
              :selectOptions="optionsIndicatived"
              :disabled="noEdit"
              v-model="form.phone_country_abrev"
            />
            <BaseInput
              v-if="noEdit"
              type="text"
              :required="true"
              label="Número de celular"
              v-model="form.phone"
              :disabled="noEdit"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseSelect
              v-if="!noEdit"
              ref="phone_country_abrev"
              id="phone_country_abrev"
              label="País del teléfono"
              required="true"
              :selectOptions="optionsIndicatived"
              :disabled="noEdit"
              v-model="form.phone_country_abrev"
            />
            <BaseNumber
              v-if="!noEdit"
              ref="cellphone"
              :required="true"
              label="Número de celular"
              placeholder="Fijo, Ej: 6011234567 o Celular, Ej: 3211234567"
              v-model="form.phone"
              required="true"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseSelect
              ref="department"
              id="department"
              label="Seleccione un departamento"
              :selectOptions="formOptions.optionsDepartments"
              v-model="form.departamento_cod"
              :disabled="noEdit"
              @input="resetMunicipality()"
            />
            <BaseSelect
              ref="city"
              id="city"
              label="Seleccione un municipio"
              :selectOptions="optionsMunicipalities"
              v-model="form.municipio_cod"
              :disabled="noEdit"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              ref="email"
              type="text"
              label="Correo electrónico"
              v-model="form.email"
              :disabled="noEdit"
              placeholder="ejemplo@ejemplo.com"
              :maxlength="50"
            />
            <BaseInput
              ref="email"
              type="text"
              label="Correo electrónico de notificación"
              v-model="form.email_notification"
              :disabled="noEdit"
              placeholder="ejemplo@ejemplo.com"
              :maxlength="50"
            />
          </div>
        </div>

        <!-- #region Persona júridica -->
        <div v-if="form.person.type_identification == 3">
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              ref="names"
              type="text"
              label="Nombres"
              placeholder="Julio Roman"
              required="true"
              :maxlength="25"
              v-model="form.first_name"
              :disabled="noEdit"
            />
            <BaseInput
              ref="last_name"
              type="text"
              label="Apellidos"
              v-model="form.last_name"
              :disabled="noEdit"
              placeholder="Henriquez"
              required="true"
              :maxlength="24"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              type="text"
              :required="true"
              label="Tipo de identificación"
              v-model="form.person.type_identification_name"
              :disabled="true"
            />
            <BaseInput
              ref="nit"
              type="text"
              id="nit"
              label="NIT"
              v-model="form.person.identification"
              :disabled="true"
              v-if="form.person.identification"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              ref="company_name"
              type="text"
              id="razonSocial"
              label="Razón social"
              v-model="form.business_name"
              :disabled="noEdit"
              required="true"
            />
            <BaseInput
              v-if="noEdit"
              type="text"
              :required="true"
              label="Número de celular"
              placeholder="123412341234"
              v-model="form.phone"
              :disabled="true"
            />
            <BaseNumber
              v-if="!noEdit"
              ref="cellphone"
              :required="true"
              label="Número de celular"
              placeholder="123412341234"
              v-model="form.phone"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseSelect
              ref="department"
              id="department"
              label="Seleccione un departamento"
              :selectOptions="formOptions.optionsDepartments"
              v-model="form.departamento_cod"
              :disabled="noEdit"
              @input="resetMunicipality()"
            />
            <BaseSelect
              ref="city"
              id="city"
              label="Seleccione un municipio"
              :selectOptions="optionsMunicipalities"
              v-model="form.municipio_cod"
              :disabled="noEdit"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              ref="email"
              type="text"
              label="Correo electrónico"
              v-model="form.email"
              :disabled="noEdit"
              placeholder="ejemplo@ejemplo.com"
              :maxlength="50"
            />
            <BaseInput
              ref="address"
              id="address"
              label="Dirección física"
              v-model="form.address"
              :maxlength="50"
              placeholder="Ejemplo: Cra. 1 ## 1A-11"
              :disabled="noEdit"
            />
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <BaseInput
              ref="email"
              type="text"
              label="Correo electrónico de notificación"
              v-model="form.email_notification"
              v-if="form.email_notification || !noEdit"
              :disabled="noEdit"
              placeholder="ejemplo@ejemplo.com"
              :maxlength="50"
            />
          </div>
        </div>
        <!-- #endregion -->
        <div v-if="feedback">
          <p class="mt-2 mb-4 text-sm text-center text-red-500">
            {{ feedback }}
          </p>
        </div>
        <div
          v-if="!noEdit"
          class="grid grid-cols-2 mt-9 flex justify-end mx-auto gap-5 w-11/12"
        >
          <a-button
            type="danger"
            @click="cancelEdit"
            shape="round"
            ghost
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">CANCELAR</span>
          </a-button>
          <a-button
            type="primary"
            @click="saveForm"
            shape="round"
            :block="true"
            :disabled="!changedForm"
            :loading="loading"
          >
            <span class="mx-2">GUARDAR</span>
          </a-button>
        </div>
      </form>
    </div>
    <div
      v-if="loading"
      class="spinner absolute w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { checkPhone } from "../../../utils/twoFactorAuthenticate";
export default {
  props: {
    userIdToGetData: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        first_name: "",
        business_name: "",
        last_name: "",
        departamento_cod: "",
        municipio_cod: "",
        phone: "",
        phone_country_abrev: "",
        email: "",
        address: "",
        email_notification: "",
        person: {
          identification: "",
          type_identification: "",
          type_identification_name: "",
          birth_date: "",
        },
      },
      localUserId: null,
      optionsMunicipalities: [],
      loading: false,
      noEdit: true,
      feedback: "",
      oldForm: {},
      disabledDate: (current) => {
        // Can not select days after today
        return current && current > moment().endOf("day");
      },
    };
  },
  created() {
    this.localUserId = this.userIdToGetData;
    this.getUser(this.userIdToGetData);
  },
  methods: {
    async getUser(userIdInFunction) {
      this.loading = true;
      let { data, error } = await this.$api.getUserFinanConsum(
        userIdInFunction
      );

      if (error) {
        this.feedback = error
          ? error?.error
          : "Error desconocido, intente más tarde";
        this.$notification.error({
          message: "Notificación",
          description: "Error al actualizar el perfil del usuario",
        });
      }
      if (data) {
        this.form = data;
        this.oldForm = JSON.stringify(this.form);
      }
      this.loading = false;
    },
    startEdit() {
      (this.form.phone_country_abrev = this.form.phone_country_abrev
        ? this.form.phone_country_abrev
        : ""),
        (this.phoneOrigin = this.form.phone);
      this.phoneCountryAbrevOrigin = this.form.phone_country_abrev;
      this.noEdit = false;
    },
    cancelEdit() {
      this.noEdit = true;
    },
    resetMunicipality() {
      this.form.municipio_cod = "";
    },
    async saveForm() {
      // Validaciones.
      if (this.checkForm() && this.validateRequiredField()) {
        // Verificación de si se han realizado cambios en el teléfono (país o numero)
        if (
          this.phoneOrigin !== this.form.phone ||
          this.phoneCountryAbrevOrigin !== this.form.phone_country_abrev
        ) {
          if (!this.validateRecalculatePhone()) {
            return;
          }
        }

        this.loading = true;
        const fieldsUpdated = {};
        const originalForm = JSON.parse(this.oldForm);
        for (const key in this.form) {
          if (typeof this.form[key] === "object" && this.form[key] !== null) {
            for (const subKey in this.form[key]) {
              if (this.form[key][subKey] !== originalForm[key][subKey]) {
                fieldsUpdated[subKey] = this.form[key][subKey];
              }
            }
          } else if (this.form[key] !== originalForm[key]) {
            fieldsUpdated[key] = this.form[key];
          }
        }
        const infoToUpdate = {
          id: this.localUserId,
          first_name: this.form.first_name,
          business_name: this.form.business_name,
          last_name: this.form.last_name,
          departamento_cod:
            this.form.departamento_cod == ""
              ? null
              : this.form.departamento_cod,
          municipio_cod:
            this.form.municipio_cod == "" ? null : this.form.municipio_cod,
          phone_country_abrev: this.form.phone_country_abrev,
          phone_type_id: this.form.phone_type_id,
          phone_indicative: this.form.phone_indicative,
          is_confirmed_phone: this.form.is_confirmed_phone,
          phone: this.form.phone,
          email: this.form.email == "" ? null : this.form.email,
          address: this.form.address == "" ? null : this.form.address,
          email_notification: this.form.email_notification,
          birth_date: this.form.person.birth_date,
          fieldsUpdated: fieldsUpdated,
        };
        const { data, error } = await this.$api.updateUserFinanConsum(
          infoToUpdate,
          this.localUserId
        );
        // Manejo de errores y notificaciones
        if (error) {
          this.feedback =
            error?.error || "Error desconocido, intente más tarde";
          this.$notification.error({
            message: "Notificación",
            description:
              "Error al actualizar el perfil del usuario: " + error.error,
          });
        }
        if (data) {
          this.$notification.success({
            message: "Actualización exitosa",
            description:
              "Los datos del consumidor financiero han sido actualizados correctamente",
          });

          this.form = data;
          this.noEdit = true;
          this.getUser(this.localUserId);
        }
        this.loading = false;
      }
    },
    checkForm() {
      let valid = false;
      let regex = /[^a-zñÑ\s]/i;
      let regexNIT = /[0-9]{7,}\-[0-9]{1}/;
      if (
        regex.test(this.form.first_name + "") ||
        regex.test(this.form.last_name + "")
      ) {
        this.feedback =
          "En los campos nombres y apellidos no se permiten caracteres especiales ni acentos";
        return valid;
      }

      // Validación para NIT se comenta por si habilitan la edición de NIT
      /*if (
        this.form.person.type_identification == 3 &&
        !regexNIT.test(this.form.identification_number + "")
      ) {
        this.feedback = "Ingrese un NIT válido";
        return valid;
      }*/
      if ((this.form.identification_number + "").length > 15) {
        this.feedback =
          "La longitud máxima del número de identificación debe ser 15.";
        return valid;
      }
      if (this.form.phone) {
        if (
          !this.$validator.isNumeric(this.form.phone + "") ||
          this.form.phone.toString().length < 7
        ) {
          this.feedback = "Ingrese un número de celular válido.";
          return valid;
        }

        if (this.form.phone.toString().length > 15) {
          this.feedback = "El número de teléfono no puede ser mayor a 15.";
          return valid;
        }
      }
      if (this.form.birth_date) {
        this.form.birth_date =
          typeof this.form.birth_date == "object"
            ? this.form.birth_date.format("YYYY-MM-DD")
            : this.form.birth_date;
      } else {
        delete this.form.birth_date;
      }
      if (this.form.email && !this.$validator.isEmail(this.form.email)) {
        this.feedback = "Ingrese un correo válido";
        return valid;
      } else if (
        this.form.emailConfirmation &&
        !this.$validator.equals(this.form.email, this.form.emailConfirmation)
      ) {
        this.feedback = "El correo electrónico no coincide";
        return valid;
      }
      //debe existir algún medio de contacto correo electronico o dirección física o correo de notificación
      if (
        !this.form.email &&
        !this.form.address &&
        !this.form.email_notification
      ) {
        this.feedback =
          "Debe ingresar al menos un medio de contacto: correo electrónico, dirección física o correo de notificación";
        return valid;
      }

      this.feedback = "";
      valid = true;
      return valid;
    },
    validateRequiredField() {
      let refs = this.$refs;

      let valid = false;
      this.feedback = "";
      if (Object.keys(this.form).length === 0) {
        this.feedback = "Verifique los campos obligatorios";
        return valid;
      }

      for (let ref in refs) {
        if (refs[ref] && refs[ref].required && refs[ref].value === "") {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      }
      return true;
    },
    validateRecalculatePhone() {
      let valid = false;
      if (this.form.phone) {
        const { getNumber, isAllowed, typePhone, indicativePhone } = checkPhone(
          this.form.phone,
          this.form.phone_country_abrev
        );

        if (isAllowed) {
          this.feedback = "";
          this.form.phoneTransform = getNumber;
          this.form.phone_type_id = typePhone;
          this.form.phone_indicative = indicativePhone;
          this.form.is_confirmed_phone = false;
          valid = true;
        } else {
          this.feedback = "Introduzca un número de teléfono válido";
        }
      }
      return valid;
    },
  },
  computed: {
    user: function () {
      return this.$store.state.session.user;
    },
    formOptions: function () {
      return this.$store.state.form;
    },
    changedForm: function () {
      if (this.oldForm !== JSON.stringify(this.form)) {
        return true;
      }
      return false;
    },
    optionsIndicatived: function () {
      return this.$store.state.form?.optionsCountriesAbrevs;
    },
  },
  watch: {
    "form.departamento_cod": async function () {
      if (this.form.departamento_cod == "") {
        return;
      }
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.form.departamento_cod }
      );
      this.optionsMunicipalities = options;
    },
    userIdToGetData(newVal) {
      this.localUserId = newVal;
    },
  },
};
</script>
